import * as React from 'react'
import Layout from '../components/Layout'
import Section from '../components/Section'
import Navigation from '../components/Navigation'
import PressReleaseSection from '../components/PressReleaseSection'
import Footer from '../components/Footer'

const PrivacyPolicy: React.FC = () => (
  <Layout>
    <Section color="primary" clip={{ bottomRight: true }}>
      <Navigation />
    </Section>
    <Section color="white">
      <PressReleaseSection />
    </Section>
    <Section color="secondary" clip={{ topLeft: true }} glue>
      <Footer />
    </Section>
  </Layout>
)

export default PrivacyPolicy
