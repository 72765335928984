import styled from 'styled-components'

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 7rem;
`

export const Title = styled.h1`
  font-size: 1.875rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
`

type TextProps = {
  bold?: boolean
  italic?: boolean
  underline?: boolean
  centered?: boolean
}

export const Text = styled.p<TextProps>`
  font-size: 1.125rem;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  text-align: ${({ centered }) => (centered ? 'center' : 'start')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  line-height: 1.5;
  margin: 0 0 1.5rem;
`

export const PressImageWrapper = styled.div`
  margin: 3rem auto;
`

export const Divider = styled.hr`
  border-top: 1px solid black;
  width: 95%;
  margin-left: 1%;
  margin-bottom: 2rem;
`
