import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import * as S from './PressReleaseSectionAtoms'
import socials from '../../constants/socials'

const PressReleaseSection: React.FC = () => (
  <S.Container>
    <S.Title>Power Retro - agilis fejlesztést támogató interaktív szoftver megoldás</S.Title>

    <S.PressImageWrapper>
      <StaticImage
        src="../../assets/images/press-release.png"
        alt="Press release"
        layout="constrained"
        placeholder="none"
        quality={100}
      />
    </S.PressImageWrapper>

    <S.Text bold>
      A Tappointment Kft. az NKFIH támogatásával olyan rendszert fejleszt, amely az agilis
      módszertanokat alkalmazó szervezeteket a hatékonyság növelésében és az értékteremtésben
      segíti.
    </S.Text>

    <S.Text>
      A napjainkban elterjedt agilis fejlesztési módszertan egyik sajátossága az ún. retrospektív
      megbeszélés, amelynek a célja, hogy a csapat közösen tekintsen vissza az elmúlt időszakra,
      vizsgálják felül az együttműködésük mikéntjét, és állapítsák meg, hogyan dolgozhat a csapat
      jobban a következő időszakban. A jelenleg elérhető online retrospektív eszközök hátránya, hogy
      az adatok nem érhetőek el egységesen egy projektmenedzsment rendszeren belül, nehezen adnak
      átfogó képet a fejlesztési és működési folyamat egészéről hosszabb időtávon.
    </S.Text>

    <S.Text>
      Felismerve ezt a hiányosságot a Tappointment Kft. egy új retrospektív rendszer fejlesztésébe
      kezdett Power Retro néven, amely a világ jelenleg legnagyobb és legnépszerűbb
      projektmenedzsment rendszeréhez, az Atlassian JIRA-hoz készül és az Atlassian Marketplace
      szolgáltatásában lesz elérhető a tervek szerint az év végén. A Power Retro egyedi
      megközelítésben vezeti végig a retrospektív résztvevőit a teljes folyamaton és innovatív
      megoldásokkal teszi hatékonyabbá és játékossá a retrospektív eseményeket. A rendszer egyik
      legnagyobb erőssége, hogy a résztvevőknek nem kell elhagyniuk az Atlassian ökoszisztémát, ha
      egy retrospektív megbeszélést szeretnének tartani, valamint a megbeszélt elemeket is a
      rendszeren belül le tudják dokumentálni és követni historikusan.
    </S.Text>

    <S.Text>
      A Power Retro rendszer nagyban tudja majd növelni a csapatok hatékonyságát, mert nem töredezik
      szét az információ több külső platform között, minden megtalálható egy helyen, könnyen
      olvasható és kereshető formában. A Power Retro külön figyelmet szentel a “zaj” kiszűrésére is,
      ezért a felhasználók mindig csak az aktuális és releváns információkkal találkoznak, amikor
      megnyitják az applikációt, tovább növelve ezzel a csapattagok hatékonyságát. Az alkalmazás
      hasznos kimutatásokkal és statisztikákkal segíti elő a menedzsment és a csapat folyamatos
      fejlődését. A Power Retro a tervek szerint a nyár folyamán lesz elérhető az Atlassian
      Marketplace szolgáltatásában.
    </S.Text>

    <S.Text>
      A <strong>Nemzeti Kutatási, Fejlesztési és Innovációs Hivatal</strong>, amely
      kutatás-fejlesztéssel és innovációval kapcsolatos projektek közfinanszírozású támogatását
      felügyeli, 19,980,000 forinttal támogatta ezt a technológiailag előremutató projektet, amely
      összeg a fejlesztési költségek 60%-át teszi ki (Támogatói okirat száma:
      2020-1.1.1-KKV-START-2020-00038. Projekt neve: Olyan Atlassian Marketplace kiegészítő
      létrehozása, amely segít a csapatoknak retrospektív elemek létrehozásában, rendszerezésében és
      követésében).
    </S.Text>

    <S.Text>
      A <strong>Tappointment Kft.</strong> 2014-ben alakult startup, amely ügyfelei egyre növekvő
      igényeinek megfelelően, egyedi, professzionális szoftver megoldások fejlesztésével kezdett el
      foglalkozni. A Tappointment webes alkalmazások és komplex üzleti megoldások tervezésével és
      kivitelezésével, valamint mobil applikációk fejlesztésével és rendszerüzemeltetéssel
      foglalkozik. Folyamatosan bővülő csapata kiemelkedő színvonalú megoldásokat nyújt startupok,
      valamint magyar és nemzetközi vállalatok számára is. A partnerek részére történő fejlesztések
      mellett a Társaság saját termékei is elérhetőek: az egyik a Tappointment nevet viselő mobil
      alapú időpontfoglaló rendszer, és a HUE Power IoT (Philips HUE) okos világítás vezérlő
      rendszer.
    </S.Text>

    <S.Text>
      További kérdéssel és bővebb tájékoztatással kapcsolatban keresse fel a Tappointment
      weboldalát: <a href="http://www.tappointment.com/">www.tappointment.com</a> vagy érdeklődjön a{' '}
      <a href={`mailto:${socials.MAILTO_TAPI}`}>hello@tappointment.com</a> e-mail címen.
    </S.Text>

    <S.Divider />

    <S.Text bold>
      Már elérhető a Power Retro, agilis fejlesztést támogató interaktív szoftver megoldás az
      Atlassian Marketplace szolgáltatásai között
    </S.Text>
    <S.Text>
      2022 januárjában megjelent a Tappointment Kft, az NKFIH támogatásával fejlesztett rendszere,
      amely az agilis módszertanokat alkalmazó szervezeteket a hatékonyság növelésében és az
      értékteremtésben segíti
    </S.Text>
    <S.Text>
      A napjainkban elterjedt agilis fejlesztési módszertan egyik sajátossága az ún. retrospektív
      megbeszélés, amelynek a célja, hogy a csapat közösen tekintsen vissza az elmúlt időszakra,
      vizsgálják felül az együttműködésük mikéntjét, és állapítsák meg, hogyan dolgozhat a csapat
      jobban a következő időszakban. A piacon elérhető online retrospektív eszközök hátránya, hogy
      az adatok nem érhetőek el egységesen egy projektmenedzsment rendszeren belül, nehezen adnak
      átfogó képet a fejlesztési és működési folyamat egészéről hosszabb időtávon.
    </S.Text>
    <S.Text>
      Felismerve ezt a hiányosságot a Tappointment Kft. piacra dobta a világ jelenleg legnagyobb és
      legnépszerűbb projektmenedzsment rendszeréhez, az Atlassian JIRA-hoz kapcsolódó retrospektív
      megoldását. A megoldás Power Retro néven került be az Atlassian Marketplace szolgáltatásai
      közé.
      <br /> A Power Retro agilis megoldás egyedi megközelítésben vezeti végig a retrospektív
      résztvevőit a teljes folyamaton és innovatív megoldásokkal teszi hatékonyabbá és játékossá a
      retrospektív eseményeket. A rendszer egyik legnagyobb erőssége, hogy a résztvevőknek nem kell
      elhagyniuk az Atlassian ökoszisztémát, ha egy retrospektív megbeszélést szeretnének tartani,
      valamint a megbeszélt elemeket is a rendszeren belül le tudják dokumentálni és követni
      historikusan.
    </S.Text>
    <S.Text>
      A rendszer nagyban tudja növelni a csapatok hatékonyságát, mert nem töredezik szét az
      információ több külső platform között, minden megtalálható egy helyen, könnyen olvasható és
      kereshető formában. A Power Retro külön figyelmet szentel a “zaj” kiszűrésére is, ezért a
      felhasználók mindig csak az aktuális és releváns információkkal találkoznak, amikor megnyitják
      az applikációt, tovább növelve ezzel a csapattagok hatékonyságát. Az alkalmazás hasznos
      kimutatásokkal és statisztikákkal segíti elő a menedzsment és a csapat folyamatos fejlődését.
    </S.Text>
    <S.Text>
      A <strong>Nemzeti Kutatási, Fejlesztési és Innovációs Hivatal</strong>, amely
      kutatás-fejlesztéssel és innovációval kapcsolatos projektek közfinanszírozású támogatását
      felügyeli, 19,980,000 forinttal támogatta ezt a technológiailag előremutató projektet, amely
      összeg a fejlesztési költségek 60%-át teszi ki (Támogatói okirat száma:
      2020-1.1.1-KKV-START-2020-00038. Projekt neve: Olyan Atlassian Marketplace kiegészítő
      létrehozása, amely segít a csapatoknak retrospektív elemek létrehozásában, rendszerezésében és
      követésében).
    </S.Text>
    <S.Text>
      A <strong>Tappointment Kft.</strong> 2014-ben alakult startup, amely ügyfelei egyre növekvő
      igényeinek megfelelően, egyedi, professzionális szoftver megoldások fejlesztésével kezdett el
      foglalkozni. A Tappointment webes alkalmazások és komplex üzleti megoldások tervezésével és
      kivitelezésével, valamint mobil applikációk fejlesztésével és rendszerüzemeltetéssel
      foglalkozik. Folyamatosan bővülő csapata kiemelkedő színvonalú megoldásokat nyújt startupok,
      valamint magyar és nemzetközi vállalatok számára is. A partnerek részére történő fejlesztések
      mellett a Társaság saját termékei is elérhetőek: az egyik a Tappointment nevet viselő mobil
      alapú időpontfoglaló rendszer, és a HUE Power IoT (Philips HUE) okos világítás vezérlő
      rendszer.
    </S.Text>
    <S.Text>
      További kérdéssel és bővebb tájékoztatással kapcsolatban keresse fel a Tappointment
      weboldalát: <a href="https://www.tappointment.com">www.tappointment.com </a>
      vagy érdeklődjön a <a href="mailto:hello@tappointment.com">hello@tappointment.com</a> e-mail
      címen.
    </S.Text>
  </S.Container>
)

export default PressReleaseSection
